// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Minty 4.0.0
// Bootswatch

//
// Color system
//

$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #f7f7f9;
$gray-300: #eceeef;
$gray-400: #ced4da;
$gray-500: #aaa;
$gray-600: #888;
$gray-700: #5a5a5a;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #FF7851;
$orange:  #fd7e14;
$yellow:  #FFCE67;
$green:   #56CC9D;
$teal:    #20c997;
$cyan:    #6CC3D5;

$theme-colors: (
  primary: #78C2AD,
  secondary: #F3969A,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);

// Body

$body-color:                $gray-600;

// Components

$border-radius:               .4rem;
$border-radius-lg:            .6rem;
$border-radius-sm:            .3rem;

// Fonts

$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$headings-font-family:        "Amaranth", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$headings-color:              $gray-700;

// Tables

$table-border-color:          rgba(0,0,0,0.05);

// Dropdowns

$dropdown-link-hover-color:         $white;
$dropdown-link-hover-bg:            map-get($theme-colors, "secondary");

// Navbar

$navbar-dark-color:                 rgba($white,.6);
$navbar-dark-hover-color:           $white;

$navbar-light-color:                rgba($black,.3);
$navbar-light-hover-color:          $gray-700;
$navbar-light-active-color:         $gray-700;
$navbar-light-disabled-color:       rgba($black,.1);

// Pagination

$pagination-color:                  $white;
$pagination-bg:                     map-get($theme-colors, "primary");
$pagination-border-color:           map-get($theme-colors, "primary");

$pagination-hover-color:            $white;
$pagination-hover-bg:               map-get($theme-colors, "secondary");
$pagination-hover-border-color:     $pagination-hover-bg;

$pagination-active-bg:              map-get($theme-colors, "secondary");
$pagination-active-border-color:    $pagination-active-bg;

$pagination-disabled-color:         $white;
$pagination-disabled-bg:            #CCE8E0;
$pagination-disabled-border-color:  $pagination-disabled-bg;

// Breadcrumbs

$breadcrumb-bg:                     map-get($theme-colors, "primary");
$breadcrumb-divider-color:          $white;
$breadcrumb-active-color:           $breadcrumb-divider-color;