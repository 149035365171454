.banner {
	height: 40vh; 
	background-image: url('https://content.sierraclub.org/outings/sites/content.sierraclub.org.outings/files/trips/photos/18245_3_JeffKnight.jpg');
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
}

#sliderIntro {
	position: absolute;
	width: 100%;
	top: 5vh;
	z-index: 1000;
	h4 {
		text-align: center;
		text-shadow: 0px 0px 5px #000, 0px 0px 5px #000;
		color: #fff;
	}
}

#carouselHomeEvents {
	height: 60vh;
	.carousel-inner, .carousel-item {
		height: 100%;
	}
	.carousel-item {
		background-size: cover;
		background-position: center;
	}
	.carousel-caption {
		background-color: rgba(1,1,1,.4);
		border-radius: 5px;
		box-shadow: 0px 0px 5px #000, inset 0px 0px 10px #999;
		* {
			color: #fff !important;
		}
	}
}

nav.navbar {
	box-shadow: 0px 0px 5px #333;
	margin-bottom: 20px;
}

.page-header {
	display: none;
	visibility: hidden;
}